.margin-top-btn {
    margin-top: 5px;
}

.margin-left-btn {
    margin-left: 10px;
}

.margin-bottom-btn {
    margin-bottom: 5px;
}